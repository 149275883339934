import React from 'react';

import DetailsTemplate from 'components/patients/DetailsTemplate';
import ContactInformation from 'components/patients/edit-profile/ContactInformation';
import EmergencyContactInformation from 'components/patients/edit-profile/EmergencyContactInformation';
import Hint from 'components/patients/edit-profile/Hint';
import PersonalInformation from 'components/patients/edit-profile/PersonalInformation';
import PrimaryCareProviderInformation from 'components/patients/edit-profile/PrimaryCareProviderInformation';
import UpdateButton from 'components/patients/edit-profile/UpdateButton';
import { PROFILE } from 'constants/routes';
import { useSetPatientProfile } from 'hooks/usePatients';
import { useFetchPatinetProfile } from 'requests/patients/patients';
import { useFetchGenders } from 'requests/profile';

export default function PatientEditProfile() {
  const fetchGenders = useFetchGenders();
  const fetchPatinetProfile = useFetchPatinetProfile();
  const clearPatinetProfile = useSetPatientProfile(() => null, []);

  React.useEffect(() => {
    fetchGenders();
    fetchPatinetProfile();
    return clearPatinetProfile;
    // eslint-disable-next-line
  }, [])

  return (
    <DetailsTemplate currentTab={PROFILE}>
      <Hint />
      <PersonalInformation />
      <ContactInformation />
      <EmergencyContactInformation />
      <PrimaryCareProviderInformation />
      <UpdateButton />
    </DetailsTemplate>
  );
}
