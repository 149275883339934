import React from 'react';

import styles from 'containers/patients/details/care-journey/JourneySectionEmptyState.module.css';

interface HealthJourneyEmptyStateProps {
  message: string;
}

export const JourneySectionEmptyState: React.FC<HealthJourneyEmptyStateProps> = ({ message }) => (
  <div className={styles.emptyState}>{message}</div>
);
