import get from 'lodash/get';
import React from 'react';

import DetailsTemplate from 'components/patients/DetailsTemplate';
import { CARE_JOURNEY } from 'constants/routes';
import { JourneySection } from 'containers/patients/details/care-journey/JourneySection';
import { usePatinetProfile } from 'hooks/usePatients';
import {
  useFetchMentalHealthJourney,
  useFetchMSKJourney,
  useFetchPlanJourney,
} from 'requests/patients/useFetchJourneys';

import styles from './CareJourney.module.css';

export const CareJourney: React.FC = () => {
  const patientProfile = usePatinetProfile();
  const plan = get(patientProfile, 'plan', '');
  const planName = plan?.name;

  function renderSupplementaryJourneys() {
    switch (planName) {
      case 'Depression & Anxiety Program':
        return (
          <JourneySection
            title="Mental Health Journey"
            useFetchJourney={useFetchMentalHealthJourney}
          />
        );
      case 'Musculoskeletal Program':
        return <JourneySection title="MSK Journey" useFetchJourney={useFetchMSKJourney} />;
      default:
        return null;
    }
  }

  return (
    <DetailsTemplate currentTab={CARE_JOURNEY}>
      <div className={styles.title}>Program</div>
      <div className={styles.programName}>{planName || 'No Plan Available'}</div>
      <JourneySection title="Health Care Journey" useFetchJourney={useFetchPlanJourney} />
      {renderSupplementaryJourneys()}
    </DetailsTemplate>
  );
};
