import React from 'react';

import { ArrowBack } from 'assets/icons/ArrowBack';
import { ArrowDown } from 'assets/icons/ArrowDown';
import { ArrowForward } from 'assets/icons/ArrowForward';
import { ArrowUp } from 'assets/icons/ArrowUp';
import { StepCheckmark } from 'assets/icons/StepCheckmark';

import { IconNames, IconProps, IconSvgProps } from './Icon.types';

/**
 * Icon component. You can use this component to render a svg component with the selected icon.
 * @param {IconNames} name - The name of the icon to render.
 * @param {number} height - The height of the icon. Not required.
 * @param {number} width - The width of the icon. Not required.
 * @param {string} className - CSS classes passed to the svg component. Not required.
 * @param {boolean} selected - This prop is for specific icons that have a selected state. Not required.
 * @param {string} strokeWeight - This prop is for specific icons that have different stroke weights. Possible values are 'thin', 'medium' and 'bold'. Default value is 'medium'.
 * @returns {React.ReactElement} The Icon component.
 */
export const Icon: React.FC<IconProps> = ({
  name,
  strokeWeight = 'medium',
  strokeOpacity = 1,
  ...rest
}) => {
  const IconComponent = iconMapper[name];

  if (!IconComponent) {
    return null;
  }

  let strokeWidth = 1.5;

  switch (strokeWeight) {
    case 'thin':
      strokeWidth = 1;
      break;
    case 'medium':
      strokeWidth = 1.5;
      break;
    case 'bold':
      strokeWidth = 2;
      break;
  }

  return <IconComponent strokeWidth={strokeWidth} strokeOpacity={strokeOpacity} {...rest} />;
};

const iconMapper: Record<IconNames, React.FC<IconSvgProps>> = {
  arrow_back: ArrowBack,
  arrow_forward: ArrowForward,
  arrow_up: ArrowUp,
  arrow_down: ArrowDown,
  step_checkmark: StepCheckmark,
};
