import classNames from 'classnames';
import React, { useRef } from 'react';

import { ReactComponent as Close } from 'assets/close.svg';
import { ReactComponent as File } from 'assets/file.svg';
import { UppercaseText } from 'common/texts';
import styles from 'components/new/FileUpload/FileUpload.module.css';

interface DocumentUploadProps {
  onFileSelect: (file?: File) => void;
  allowedFormats?: string[];
  label: string;
  file?: File;
  className?: string;
}

export const FileUpload: React.FC<DocumentUploadProps> = ({
  onFileSelect,
  allowedFormats = ['.pdf', '.doc', '.docx'],
  file,
  label,
  className,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const isValidFormat = allowedFormats.some((format) =>
        file.name.toLowerCase().endsWith(format),
      );

      if (isValidFormat) {
        onFileSelect(file);
      } else {
        alert(`Invalid file format. Allowed formats: ${allowedFormats.join(', ')}`);
      }
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  let content = (
    <div className={styles.container}>
      <button className={styles.uploadButton} onClick={triggerFileInput}>
        ⬆ Upload
      </button>
      <span className={styles.allowedFormats}>Formats allowed: {allowedFormats.join(' ')}</span>
      <input
        type="file"
        ref={fileInputRef}
        className={styles.hiddenInput}
        accept={allowedFormats.join(',')}
        onChange={handleFileUpload}
      />
    </div>
  );

  if (file) {
    const fileURL = URL.createObjectURL(file);
    content = (
      <div className={styles.fileContainer}>
        <File />
        <p className={styles.file}>
          <a href={fileURL} target="_blank" rel="noreferrer">
            {file.name}
          </a>
        </p>
        <Close className={styles.cross} onClick={() => onFileSelect()} />
      </div>
    );
  }

  return (
    <div className={classNames(styles.wrapper, className)}>
      <UppercaseText className={styles.label}>{label}</UppercaseText>
      {content}
    </div>
  );
};
