import classNames from 'clsx';
import React from 'react';

import { Badge } from 'components/new/Badge/Badge';
import { Icon } from 'components/new/Icon/Icon';
import { StepDetails } from 'requests/patients/useFetchJourneys';

import styles from './Step.module.css';

export enum StepStatus {
  COMPLETED = 'completed',
  PENDING = 'pending',
  BLOCKED = 'blocked',
  AVAILABLE = 'available',
}

export interface StepProps {
  status: StepStatus;
  label: string;
  details?: StepDetails;
  isFirstTaskInList?: boolean;
}

export const JourneyStep: React.FC<StepProps> = ({ status, label, details, isFirstTaskInList }) => {
  const renderIcon = (status: StepStatus) => {
    return (
      <div className={styles.iconWrapper}>
        <Icon name="step_checkmark" completed={status === StepStatus.COMPLETED} />
      </div>
    );
  };
  const shouldRenderPendingBanner = (status: string, details?: StepDetails): boolean => {
    return (
      status !== StepStatus.BLOCKED &&
      status !== StepStatus.COMPLETED &&
      !(status === StepStatus.AVAILABLE && details?.started_date)
    );
  };

  const renderPendingBadge = () => {
    const shouldPendingBannerRender =
      shouldRenderPendingBanner(status, details) && isFirstTaskInList;

    if (shouldPendingBannerRender) {
      return <Badge type="warning" text="Pending" />;
    }
    return null;
  };

  return (
    <div className={styles.stepContainer}>
      {renderIcon(status)}
      <div
        className={classNames(styles.step, {
          [styles.stepBlocked]: status === StepStatus.BLOCKED,
        })}
      >
        {label}
      </div>
      {renderPendingBadge()}
    </div>
  );
};
