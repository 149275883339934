import React, { useEffect } from 'react';

import Spinner from 'common/loaders/Spinner';
import { JourneyStep, StepStatus } from 'components/health-journey/step/JourneyStep';
import { Accordion } from 'components/new/Accordion/Accordion';
import { JourneySectionEmptyState } from 'containers/patients/details/care-journey/JourneySectionEmptyState';
import { Step } from 'requests/patients/useFetchJourneys';

import styles from './JourneySection.module.css';

interface JourneySectionProps {
  title: string;
  useFetchJourney: () => {
    journey: Step[];
    fetchJourney: () => Promise<void>;
    loading: boolean;
  };
}

export const JourneySection: React.FC<JourneySectionProps> = ({ title, useFetchJourney }) => {
  const { journey, fetchJourney, loading } = useFetchJourney();

  useEffect(() => {
    fetchJourney();
  }, [fetchJourney]);

  const completedSteps = journey.filter((step) => step.status === 'completed');
  const hasCompletedSteps = completedSteps.length > 0;
  const upcomingSteps = journey.filter((step) => step.status !== 'completed');
  const hasUpcomingSteps = upcomingSteps.length > 0;

  const renderCompletedTasks = () => (
    <Accordion
      label="Completed tasks"
      open
      rightLabel={hasCompletedSteps ? 'Date completed' : undefined}
    >
      <div className={styles.taskContainer}>
        {hasCompletedSteps ? (
          completedSteps.map(({ status, name, completed_date, order }) => (
            <div key={`${name}-${order}`} className={styles.completedTaskRow}>
              <JourneyStep status={status as StepStatus} label={name} />
              <span className={styles.dateCompleted}>
                {completed_date ? new Date(completed_date).toLocaleDateString() : ''}
              </span>
            </div>
          ))
        ) : (
          <JourneySectionEmptyState message="The patient hasn't completed any tasks yet." />
        )}
      </div>
    </Accordion>
  );

  const renderUpcomingTasks = () => (
    <Accordion label="Upcoming tasks" open>
      <div className={styles.taskContainer}>
        {hasUpcomingSteps ? (
          upcomingSteps.map(({ status, name, details, order }, index) => (
            <JourneyStep
              key={`${name}-${order}`}
              status={status as StepStatus}
              label={name}
              details={details}
              isFirstTaskInList={index === 0}
            />
          ))
        ) : (
          <JourneySectionEmptyState message="The patient doesn't have any upcoming tasks yet." />
        )}
      </div>
    </Accordion>
  );

  return (
    <>
      <hr className={styles.separator} />
      <div className={styles.journeySection}>
        <div className={styles.title}>{title}</div>
        {loading ? (
          <Spinner />
        ) : (
          <div className={styles.accordionContainer}>
            {renderCompletedTasks()}
            {renderUpcomingTasks()}
          </div>
        )}
      </div>
    </>
  );
};
