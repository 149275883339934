import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import CustomSelect from 'components/new/CustomSelect/CustomSelect';
import { FileUpload } from 'components/new/FileUpload/FileUpload';
import { Button } from 'components/ui2.0/Button';
import createNotification from 'utils/createNotification';
import request from 'utils/request';

import styles from './index.module.css';

interface Props {
  onUpload: () => void;
}

export const DocumentUploader: React.FC<Props> = ({ onUpload }) => {
  const [documentTypes, setDocumentTypes] = useState<{ label: string; value: string }[]>([]);
  const [selectedType, setSelectedType] = useState<string>();
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState<File>();
  const { patientId } = useParams<{ patientId: string }>();

  React.useEffect(() => {
    request({
      method: 'get',
      url: '/document-types',
    })
      .then(({ data: { data } }) =>
        setDocumentTypes(data.document_types.map(({ name }) => ({ label: name, value: name }))),
      )
      .catch((error) => console.info(error));
  }, []);

  const uploadDocument = async () => {
    if (!file || !file.name) {
      return;
    }
    setIsUploading(true);
    const formData = new FormData();
    formData.append('document', file);
    formData.append('document_type', selectedType!);
    formData.append('patient_id', patientId!);

    request({
      method: 'post',
      url: '/documents',
      data: formData,
    })
      .then(() => {
        setFile(undefined);
        setSelectedType(undefined);
        createNotification({ message: 'Document successfully uploaded', type: 'success' });
      })
      .catch((error) => {
        createNotification({ message: 'Document could not upload', type: 'error' });
        console.info(error);
      })
      .finally(() => {
        setIsUploading(false);
        onUpload();
      });
  };

  return (
    <div className={styles.documentUploader}>
      <CustomSelect
        className={styles.select}
        label="Document type"
        isLoading={!documentTypes.length}
        placeholder="Select"
        options={documentTypes}
        value={selectedType}
        changeHandler={setSelectedType}
      />
      <FileUpload
        onFileSelect={setFile}
        label="Upload document"
        file={file}
        className={styles.upload}
      />
      <div className={styles.buttonContainer}>
        <Button
          onClick={uploadDocument}
          disabled={isUploading || !file || !selectedType}
          className={styles.button}
        >
          Upload
        </Button>
      </div>
    </div>
  );
};
