import get from 'lodash/get';
import React from 'react';
import Select from 'react-dropdown-select';

import { UppercaseText } from 'common/texts';
import styles from 'components/new/CustomSelect/CustomSelect.module.css';

interface Option {
  value: string;
  label: string;
}

interface CustomSelectProps {
  label?: string;
  options?: Option[];
  className?: string;
  placeholder?: string;
  value?: string;
  changeHandler: (value?: string) => void;
  isRequired?: boolean;
  isValid?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

interface DropdownHandleRendererProps {
  state: {
    dropdown: boolean;
  };
}

const DropdownHandleRenderer: React.FC<DropdownHandleRendererProps> = ({ state: { dropdown } }) => {
  return <div className={`${styles.handler} ${dropdown ? styles.dropdownOpen : ''}`} />;
};

const CustomSelect: React.FC<CustomSelectProps> = ({
  label,
  options = [],
  className = '',
  placeholder = '',
  value,
  changeHandler,
  isRequired,
  isLoading,
  disabled,
}) => {
  const handleChange = React.useCallback(
    (values: Option[]) => {
      changeHandler(get(values, '[0].value'));
    },
    [changeHandler],
  );

  if (isLoading) {
    return (
      <div className={`${styles.loader} ${className}`}>
        {label && <div className={styles.loaderLabel} />}
        <div className={styles.loaderSelect} />
      </div>
    );
  }

  const selectedOption = options.find((option) => option.value === value);

  return (
    <div className={`${styles.wrapper} ${className}`}>
      {label && (
        <UppercaseText className={styles.label}>
          {label}
          {isRequired && <span className={styles.required}>*</span>}
        </UppercaseText>
      )}
      <Select
        className={styles.select}
        disabled={disabled}
        values={selectedOption ? [selectedOption] : []}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        searchable={false}
        dropdownHandleRenderer={({ state }) => <DropdownHandleRenderer state={state} />}
      />
    </div>
  );
};

export default React.memo(CustomSelect);
