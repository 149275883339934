import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import request from 'utils/request';

interface RouteParams {
  patientId: string;
}

export interface Step {
  name: string;
  status: string;
  completed_date: string | null;
  details: StepDetails;
  order: number;
}

export interface StepDetails {
  started_date?: string | null;
}

interface UseFetchJourneyReturn {
  journey: Step[];
  fetchJourney: () => Promise<void>;
  loading: boolean;
}

/**
 * Generic hook to fetch a journey by endpoint.
 */
const useFetchJourney = (endpoint: string): UseFetchJourneyReturn => {
  const { patientId } = useParams<RouteParams>();
  const [journey, setJourney] = useState<Step[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchJourney = useCallback(async () => {
    if (!patientId) {
      return;
    }

    setLoading(true);
    try {
      const { data }: { data: Step[] } = await request({
        method: 'get',
        url: `/patients/${patientId}/${endpoint}`,
      });
      setJourney(data);
    } catch (err) {
      console.error(`Failed to fetch ${endpoint}`);
    } finally {
      setLoading(false);
    }
  }, [patientId, endpoint]);

  if (!patientId) {
    return { journey: [], fetchJourney, loading: false };
  }

  return { journey, fetchJourney, loading };
};

/**
 * Specific hooks using the generic `useFetchJourney` hook.
 */
export const useFetchPlanJourney = () => useFetchJourney('plan-journey');
export const useFetchMentalHealthJourney = () => useFetchJourney('mental-health-journey');
export const useFetchMSKJourney = () => useFetchJourney('msk-journey');
