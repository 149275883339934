import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import styles from 'components/new/Accordion/Accordion.module.css';
import { Icon } from 'components/new/Icon/Icon';

export interface AccordionProps {
  children: React.ReactNode;
  label: string;
  rightLabel?: string;
  open?: boolean;
  onChange?: (isOpen: boolean) => void;
}

/**
 * Accordion component. This component is used to display a collapsible content.
 * @param {React.ReactNode} children - The content of the accordion.
 * @param {boolean} open - A boolean to control the visibility of the accordion.
 * @param {string} label - The label of the accordion.
 * @param {string} rightLabel - The right label of the accordion.
 * @param {(isOpen: boolean) => void} onChange - A callback function called when the accordion is toggled.
 * @returns {React.FC<AccordionProps>} The Accordion component
 * @example
 * <Accordion label="Label" rightLabel="Right Label" open={false} onChange={(value) => console.info(`Accordion is ${value ? 'open' : 'closed'}`)}>
 *  <Content />
 * </Accordion>
 */
export const Accordion: React.FC<AccordionProps> = ({
  children,
  open = false,
  label,
  rightLabel,
  onChange = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleToggle = (): void => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        onChange(isOpen);
      },
      isOpen ? 0 : 500,
    );
    return () => clearTimeout(timeout);
  }, [isOpen]);
  return (
    <div className={styles.accordion}>
      <button onClick={handleToggle} className={styles.handle}>
        <h2>{label}</h2>
        <div className={styles.handleRight}>
          {rightLabel && isOpen && <h3>{rightLabel}</h3>}
          <div>
            <Icon name={isOpen ? 'arrow_up' : 'arrow_down'} width={20} height={20} />
          </div>
        </div>
      </button>
      {isOpen && (
        <div className={styles.content}>
          <div className={classNames({ [styles.open]: isOpen })}>{children}</div>
        </div>
      )}
    </div>
  );
};
