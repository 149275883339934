import classNames from 'classnames';
import React from 'react';

import styles from 'components/new/Badge/Badge.module.css';
import { BadgeProps } from 'components/new/Badge/Badge.types';
import { Icon } from 'components/new/Icon/Icon';

/**
 * It can be used to show the type of content like PDF, Video, Typeform, etc.
 * It can also be used to show the status of the content like 'No Sections', 'Please note that'.
 * @param {BadgeType} type - Type of the badge.
 * @param {BadgeName} text - Text of the badge
 * @param {BadgeSize} size - Pass size if you want to override the default size (optional).
 * @param {IconNames} icon - Pass an icon name to use any available icon (optional).
 * @param {IconNames} iconPosition - Pass an icon name to use any available icon (optional).
 * @returns {React.FC<BadgeProps>} The Badge component.
 */
export const Badge: React.FC<BadgeProps> = ({
  type,
  text,
  size = 'small',
  iconPosition = 'left',
  icon,
}) => {
  const iconSize = size === 'large' ? 20 : 16;

  return (
    <div
      className={classNames(
        styles.badgeContainer,
        {
          [styles.badgePrimary]: type === 'primary',
          [styles.badgeNeutral]: type === 'neutral',
          [styles.badgeError]: type === 'error',
          [styles.badgeWarning]: type === 'warning',
          [styles.badgeSuccess]: type === 'success',
        },
        {
          [styles.badgeLarge]: size === 'large',
          [styles.badgeSmall]: size === 'small',
        },
        {
          [styles.badgeIconRight]: icon && iconPosition === 'right',
        },
      )}
    >
      {icon && <Icon name={icon} height={iconSize} width={iconSize} strokeWeight="bold" />}
      <span
        className={classNames(styles.badgeText, {
          [styles.badgeTextLarge]: size === 'large',
          [styles.badgeTextSmall]: size === 'small',
        })}
      >
        {text}
      </span>
    </div>
  );
};
