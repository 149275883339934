import React, { ReactElement } from 'react';

import { IconSvgProps } from 'components/new/Icon/Icon.types';

interface CheckmarkProps extends IconSvgProps {
  completed?: boolean;
}

export const StepCheckmark = ({
  height = 20,
  width = 20,
  strokeWidth = 2,
  completed,
  className,
}: CheckmarkProps): ReactElement => {
  return completed ? (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" fill="#0EAD79" />
      <path
        d="M8.68118 11.9993L10.8939 14.2972L15.3195 9.70142"
        stroke="white"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" fill="#223232" fillOpacity="0.15" />
      <path
        d="M8.68118 11.9993L10.8939 14.2972L15.3195 9.70142"
        stroke="#223232"
        strokeOpacity="0.5"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
